












































import { Prop, Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

@Component
export default class TasqsListFilteringPopup extends mixins() {
  @Prop({ type: Array, default: [] }) options!: any;

  @Prop({ type: String, default: 'Placeholder' }) placeholder!: string;

  @Prop({ type: String, default: '' }) title!: string;

  @Prop({ type: String, default: '' }) label!: string;

  openDropdownPopup(e) {
    this.$emit('open-dropdown-component', e);
  }
}
